<template>
    <section class="landing-page">
    </section>
</template>

<script>
export default {
  name: 'landing-page',
  

  mounted() {
      
      let is_logined = this.$root._AUTH().is_authed();
      
      if(is_logined){
          this.$router.push('/g1/desksweek/' + '?t=' + this.$root._SYS().add_v());
//          this.$router.push('/g1/first/');
      }
  },
   
   computed: {
     
//        is_logined () {
//            var is_logined=this.$root._AUTH().is_authed();
//            if  (is_logined) {
//                return true;
//            } else { 
//                //console.log(this.$router.currentRoute.path);
//                return false;
//            }
//          
//        },
   
   },
}



</script>
